import { FourOhFour, StudentPageInner } from "@app/components";
import { RhymequizAssignment, useRhymequizAssignmentByIdQuery } from "@app/graphql";
import { NextPage } from "next";
import { useRouter } from "next/router";
import React from "react";

const StudentQuiz: NextPage = () => {
  const router = useRouter();
  const assignmentId = router.query.assignment as string;

  const rhymequizAssignmentQuery = useRhymequizAssignmentByIdQuery({ variables: { id: assignmentId } });

  return !rhymequizAssignmentQuery.data?.rhymequizAssignment ? (
    <FourOhFour></FourOhFour>
  ) : (
    <StudentPageInner
      rhymequizAssignment={rhymequizAssignmentQuery.data.rhymequizAssignment as RhymequizAssignment}
      loading={rhymequizAssignmentQuery.loading}
      error={rhymequizAssignmentQuery.error}
    ></StudentPageInner>
  );
};

export default StudentQuiz;
